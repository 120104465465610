import * as React from 'react';
import { Global } from '@emotion/react';
import { ReactElement, ReactNode, useEffect } from 'react';
import { LayoutStyles } from './styles';
import { Footer } from '../../modules/Footer/Footer';
import { TopBar } from '../../modules/TopBar/TopBar';
import * as Styled from './styles';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps): ReactElement => {
  const location = useLocation();
  useEffect(()=>{

    if((location.pathname == '/save-test1/' || location.pathname == '/save-test1') 
      || (location.pathname == '/sale-test1/' || location.pathname == '/sale-test1')) {
      
      const deal_qstr =  window.location.href.split('?')[1];
      const getRandomItem = (arr) => {
        const randomIndex = Math.floor(Math.random() * arr.length);
        const item = arr[randomIndex];
        return item;
      }
      let swab = localStorage.getItem('swab');
      if(!['/save-test1', '/sale-test1'].includes(swab))
        swab = null
      
      if(swab === null){
        const ab =  getRandomItem(['/save-test1', '/sale-test1']);
        localStorage.setItem('swab',ab);
        
        window.location.replace((typeof deal_qstr !== 'undefined') ? ab + '?' + deal_qstr : ab)
      }else{
        if(!location.pathname.includes(swab))
          window.location.replace((typeof deal_qstr !== 'undefined') ?  swab + '?' + deal_qstr : swab)
      }
    }
  }, []);


  let title = 'Diagram, Flowchart & Process Map Software | SketchWow';
  // eslint-disable-next-line max-len
  let description =
    'Software to create fast, simple sketches to visualize your ideas, venn diagrams, flowcharts, process maps, plans, charts and UI mockups';
  
  if((location.pathname == '/reviews/' || location.pathname == '/reviews')){
    title = 'Read SketchWow Reviews From Real Customers'
    description = 'These SketchWow testimonials and reviews are taken directly from real Facebook and Instagram comments'
  }


  const showTopbar = () => {

    if((location.pathname == '/lifetime/' || location.pathname == '/lifetime') 
    || (location.pathname == '/deal/' || location.pathname == '/deal') 
    || (location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal') 
    || (location.pathname == '/sale/' || location.pathname == '/sale') 
    || (location.pathname == '/save/' || location.pathname == '/save') 
    || (location.pathname == '/yt-deal/' || location.pathname == '/yt-deal') 
    || (location.pathname == '/offer/' || location.pathname == '/offer') 
    || (location.pathname == '/promo/' || location.pathname == '/promo')
    || (location.pathname == '/save-test/' || location.pathname == '/save-test')
    || (location.pathname == '/sale-test/' || location.pathname == '/sale-test')
    || (location.pathname == '/sale-min/' || location.pathname == '/sale-min')
    || (location.pathname == '/' || location.pathname == '')
    ) {
    //if((location.pathname == '/sale/' || location.pathname == '/sale') || (location.pathname == '/save-test/' || location.pathname == '/save-test') ) {
      return(<></>)
    }else{
      return(<TopBar />)
    }
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }} >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="diagramming tool, venn diagram generator, draw flowcharts, infographic software, ui mockup tool" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="/og-image.png" />
        {
          ((location.pathname == '/trial/' || location.pathname == '/trial') 
                || (location.pathname == '/lifetime/' || location.pathname == '/lifetime') 
                || (location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal') 
                || (location.pathname == '/deal/' || location.pathname == '/deal') 
                || (location.pathname == '/save/' || location.pathname == '/save') 
                || (location.pathname == '/promo/' || location.pathname == '/promo') 
                || (location.pathname == '/saver/' || location.pathname == '/saver') 
                || (location.pathname == '/sale/' || location.pathname == '/sale') 
                || (location.pathname == '/yt-deal/' || location.pathname == '/yt-deal') 
                || (location.pathname == '/49/' || location.pathname == '/49')
                || (location.pathname == '/lifetime-plans/' || location.pathname == '/lifetime-plans')
                || (location.pathname == '/lifetime-price/' || location.pathname == '/lifetime-price')
                || (location.pathname == '/saves/' || location.pathname == '/saves') 
                || (location.pathname == '/sale-video/' || location.pathname == '/sale-video') 
                || (location.pathname == '/save-test/' || location.pathname == '/save-test') 
                || (location.pathname == '/sale-test/' || location.pathname == '/sale-test') 
                || (location.pathname == '/sale-min/' || location.pathname == '/sale-min')
                || (location.pathname == '/offer/' || location.pathname == '/offer') 

              ) &&
          <meta name="robots" content="noindex, nofollow"/>
        }


        {/* {  ((location.pathname == '/pricing/' || location.pathname == '/pricing') ) &&
          <style>

          </style>
        }
        <script type="module">
          import Swiper from "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.mjs";
        </script> */}
        
      </Helmet>
      <Global styles={LayoutStyles} />
      <Styled.Wrapper>
        { showTopbar() }
        <main>{children}</main>
        <Footer />
      </Styled.Wrapper>
    

    </>
  );
};
