import React, { ReactElement, useEffect, useState } from 'react';
import * as Styled from './styles';
import {  colors } from '../../styles/variables';
import { useLocation } from '@reach/router';
import { css } from '@emotion/react';
import { Pricing } from '../Pricing/Pricing';

export const Footer = (): ReactElement => { 

  const location = useLocation();

  let isMobile  = false; 

  if(typeof window !== 'undefined'){
    const [width, setWidth] = useState<number>(window.innerWidth);
    if(width <= 768) isMobile = true
  }


  const noFooter = () => {
    return ((location.pathname == '/lifetime/' || location.pathname == '/lifetime') 
          || (location.pathname == '/deal/' || location.pathname == '/deal') 
          || (location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal') 
          || (location.pathname == '/sale/' || location.pathname == '/sale') 
          || (location.pathname == '/promo/' || location.pathname == '/promo')
          || (location.pathname == '/save/' || location.pathname == '/save')
          || (location.pathname == '/reviews/' || location.pathname == '/reviews'))
  }

  const orderLink = () => {

    if(location.pathname == '/' || location.pathname == '/pricing/' || location.pathname == '/pricing'){
      return(<Styled.NavListItem><Styled.NavListLink href="https://sketchwow.com/pricing">Order Now</Styled.NavListLink></Styled.NavListItem>)
    }else if(location.pathname == '/trial/' || location.pathname == '/trial'){
      return(<Styled.NavListItem><Styled.NavListLink id="nav-list-link" href="https://checkout.sketchwow.com/?add-to-cart=2064">Order Now</Styled.NavListLink></Styled.NavListItem>)
    }else if((location.pathname == '/reviews/' || location.pathname == '/reviews')
              || (location.pathname == '/privacy-policy/' || location.pathname == '/privacy-policy')
              || (location.pathname == '/terms-service/' || location.pathname == '/terms-service')
              || (location.pathname == '/cookie-policy/' || location.pathname == '/cookie-policy')){
      return (<></>)
    }else{
      return(<Styled.NavListItem><Styled.NavListLink id="nav-list-link"  href="https://checkout.sketchwow.com/?add-to-cart=407">Order Now</Styled.NavListLink></Styled.NavListItem>)
    }
  }

  const pricingLink = () => {

    if( noFooter() ){
      return(<></>)
    }else{
      return(<Styled.NavListItem><Styled.NavListRouterLink href="/pricing">Pricing</Styled.NavListRouterLink></Styled.NavListItem>)
    }
  }

  
  const year = new Date().getFullYear()

  useEffect(()=>{
    const deal_qstr =  window.location.href.split('?')[1];
    let link = "https://checkout.sketchwow.com?add-to-cart=407";
    if(location.pathname == '/' || location.pathname == '/pricing/' || location.pathname == '/pricing'
              || (location.pathname == '/privacy-policy/' || location.pathname == '/privacy-policy')
              || (location.pathname == '/terms-service/' || location.pathname == '/terms-service')
              || (location.pathname == '/cookie-policy/' || location.pathname == '/cookie-policy')){
      link = "https://sketchwow.com/pricing"
    }else if(location.pathname == '/trial/' || location.pathname == '/trial'){
      link = "https://checkout.sketchwow.com/?add-to-cart=2064"; 
    }else if(location.pathname == '/sale/' || location.pathname == '/sale'){
      link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sl";  //link = "#DealPricing"; //
    }else if(location.pathname == '/save/' || location.pathname == '/save'){
      link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sv";  //link = "#DealPricing"; //
    }else if(location.pathname == '/offer/' || location.pathname == '/offer'){
      link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=of";  //link = "#DealPricing"; //
    }else if(location.pathname == '/lifetime/' || location.pathname == '/lifetime'){
        link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=lt"; //link = "#DealPricing"; //
    }else if(location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal'){
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=ltd"; //link = "#DealPricing"; //
    }else if(location.pathname == '/deal/' || location.pathname == '/deal'){
        link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=dl"; //link = "#DealPricing"; //
    }else if((location.pathname == '/promo/' || location.pathname == '/promo') && localStorage.getItem('refid')){
      link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=pr&coupon_code=save10percent&ref="+localStorage.getItem('refid'); //link = "#DealPricing"; //
    }else if(location.pathname == '/promo/' || location.pathname == '/promo'){
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=pr"; //link = "#DealPricing"; //
    }else if(location.pathname == '/yt-deal/' || location.pathname == '/yt-deal'){
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=yt"; //link = "#DealPricing"; //
    // }else if(location.pathname == '/sale-test/' || location.pathname == '/sale-test'){
    //   link = "#DealPricing";
    }else{
      link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1" //link = "#DealPricing"; //
    }

    if(typeof deal_qstr !== 'undefined' && document.getElementById("nav-list-link") !== null){
     document.getElementById("nav-list-link").href = link + "&" + deal_qstr;
    }else   if(document.getElementById("nav-list-link") !== null){
      document.getElementById("nav-list-link").href = link 
    }

    // const withTP = ['/', '/lifetime-deal/', '/lifetime-deal', '/lifetime-deals/', '/lifetime-deals', '/lifetime/', '/lifetime']
    // if(!withTP.includes(location.pathname)){
    //   console.log('yes', document.querySelectorAll('div[data-campaign="8de40275-5ee1-45d9-976c-ffc919d75757"]'))
    //   document.querySelectorAll('div[data-campaign="8de40275-5ee1-45d9-976c-ffc919d75757"]').forEach((elem) => elem.parentNode.removeChild(elem));
    // }

    const addTrustPulseScript = () => {
      const script = document.createElement('script');
      script.src = 'https://a.trstplse.com/app/js/api.min.js';
      script.async=true;
      script.setAttribute("data-account", "11111")
      document.body.appendChild(script);
   };
   
//    const addPagesenseScript = () => {
//     const script = document.createElement('script');
//     script.src = 'https://cdn.pagesense.io/js/joltmarketing/43be4c293b5242379e76a4720067c514.js';
//     script.async=true;
//     document.body.appendChild(script);
//  };


//    const addCookieScript = () => {
//     const script = document.createElement('script');
//     script.src = 'https://cdn.cookie-script.com/s/17d5a67f078b2695e2eca4a82eeaf026.js';
//     //script.async=true;
//     script.setAttribute("charset", "UTF-8")
//     document.body.appendChild(script);
//  };

   const addBeaconScript = () => {
    const script = document.createElement('script');
    script.innerHTML = "window.Beacon('init', '24ee5e84-ae42-45fa-8fb5-eb5f5c4547b5')"
    document.body.appendChild(script);
 };

 const addCookieFirstScript = () => {
  const script = document.createElement('script');
  script.src = 'https://consent.cookiefirst.com/sites/localhost-6961c651-1960-422f-a3a7-c06251b24345/consent.js';
  script.async=true;
  document.body.appendChild(script);
};


const addOptinMonsterScript = () => {
  const script = document.createElement('script');
  script.innerHTML = "(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,302538,320936); "
  document.body.appendChild(script);
};


   //const withTP = ['/', '/lifetime-deal/', '/lifetime-deal', '/lifetime-deals/', '/lifetime-deals', '/lifetime/', '/lifetime']
   const withTP = ['/', '/lifetime-deal/', '/lifetime-deal','/lifetime-price/', '/lifetime-price', '/save/', '/save', '/saves/', '/saves', '/sale/', '/sale', '/deal/', '/deal', '/lifetime/', '/lifetime', '/promo/', '/promo' , '/offer/', '/offer']
   if(withTP.includes(location.pathname)){
      addTrustPulseScript()
     // <script type="text/javascript" src="" data-account="6492" async></script>
   }

  //  const withCookie = ['/sale/', '/sale']
  //  if(withCookie.includes(location.pathname)){
  //   addCookieScript()
  //  }

  // const withPS = ['/save/', '/save', '/sale/', '/sale']
  // if(withPS.includes(location.pathname)){
  //   addPagesenseScript()
  // }


   const withBeacon = ['/lifetime-deal/', '/lifetime-deal', '/promo/', '/promo', '/lifetime/', '/lifetime', '/deal/', '/deal', '/sale/', '/sale', '/save/', '/save', '/']
   if(withBeacon.includes(location.pathname)){
    addBeaconScript()
   }

   const withOptinMonster = ['/save-test/', '/save-test']
   if(withOptinMonster.includes(location.pathname)){
    
    if(isMobile){
    //   const timeout = setTimeout(function(){   
    //     addOptinMonsterScript()
    //     clearTimeout(timeout);
    //   }, 70000);
    // }else{
      addOptinMonsterScript()
    }
   }



   const withCookieFirst = ['/cookiefirsttest/', '/cookiefirsttest']
   if(withCookieFirst.includes(location.pathname)){
    addCookieFirstScript()
   }

   const addFBPixelScript = () => {
    const script = document.createElement('script');
    script.innerHTML = "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '203653241871079');fbq('track', 'PageView');"
    document.body.appendChild(script);

    const noscript = document.createElement('noscript');
    var imgns = document.createElement('img')
    imgns.setAttribute('src', "https://www.facebook.com/tr?id=203653241871079&ev=PageView&noscript=1")
    imgns.setAttribute("height", "1")
    imgns.setAttribute("width", "1")
    imgns.setAttribute("style", "display:none;")
    noscript.appendChild(imgns)
    document.body.appendChild(noscript);
  };

  const withFBPixelCode = ['/save/', '/save', '/promo/', '/promo']
  if(withFBPixelCode.includes(location.pathname)){
    addFBPixelScript()
  }

  const GTMNoScript = () => {
    // <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MS2D3RG"
    //       height="0" width="0" style={{display:"none", visibility:"hidden"}}></iframe></noscript>
    const noscript = document.createElement('noscript');
    var imgns = document.createElement('iframe')
    imgns.setAttribute('src', "https://www.googletagmanager.com/ns.html?id=GTM-MS2D3RG")
    imgns.setAttribute("height", "0")
    imgns.setAttribute("width", "0")
    imgns.setAttribute("style", "display:none;visibility:hidden;")
    noscript.appendChild(imgns)
    document.body.appendChild(noscript);

  }

  GTMNoScript()
  //  setTimeout(function(){
  //   //plausible('pageview', {props: {location: location.pathname}})
  //   //alert(location.pathname)
  // }, 3000)
   }, []);
  return(
  <>
    <Styled.Legals>
      <Styled.LegalsContent>
        <p>
        This website is not a part of the Facebook website or Meta Platforms Inc. Additionally, this site and product is not endorsed by (nor affiliated with) Facebook Or Meta Platforms Inc. in any way. Facebook is a registered trademark of Facebook Inc.
        </p>
      </Styled.LegalsContent>
    </Styled.Legals>
    <Styled.Wrapper>
      <Styled.Copyright>Copyright © {year} SketchWow.com</Styled.Copyright>
      <nav>
        <Styled.NavList>
          { /*
          <Styled.NavListItem>
            <Styled.NavListRouterLink href="/members">Members Area</Styled.NavListRouterLink>
          </Styled.NavListItem>
          */ }
          <Styled.NavListItem>
            <Styled.NavListRouterLink href="/privacy-policy">Privacy Policy</Styled.NavListRouterLink>
          </Styled.NavListItem>
          <Styled.NavListItem>
            <Styled.NavListRouterLink href="/terms-service">Terms of Service</Styled.NavListRouterLink>
          </Styled.NavListItem>
          <Styled.NavListItem>
            <Styled.NavListRouterLink href="/cookie-policy">Cookie Policy</Styled.NavListRouterLink>
          </Styled.NavListItem>
          <Styled.NavListItem>
            <Styled.NavListRouterLink href="/open-source-licenses.txt">Open Source Licenses</Styled.NavListRouterLink>
          </Styled.NavListItem>

          {location.pathname == '/' &&
            <Styled.NavListItem>
              <Styled.NavListRouterLink href="https://blog.sketchwow.com">Blog</Styled.NavListRouterLink>
            </Styled.NavListItem>
            }

            { pricingLink() }
          
            { orderLink() }

          <Styled.NavListItem>
            <Styled.NavListLink href="https://help.sketchwow.com">Support</Styled.NavListLink>
          </Styled.NavListItem>
        </Styled.NavList>
      </nav>
    </Styled.Wrapper>
  </>)

};
