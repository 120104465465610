import React, { ReactElement } from 'react';
import { useWindowScroll } from 'react-use';
import * as Styled from './styles';
import { Nav } from './Nav';
import LogoIllustration from '../../assets/logo.inline.svg';
import { css } from '@emotion/react';
import LogoIllustrationAI from '../../assets/logo.inline-AI.svg';
import { useLocation } from '@reach/router';
export const TopBar = (): ReactElement => {
  const { y } = useWindowScroll();
  const location = useLocation();
  let links = () => {

    if(location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal'){
      return(<Styled.Logo href="/lifetime-deal"><LogoIllustration />Lifetime Deal </Styled.Logo>)
    }else if(location.pathname == '/sale-test/' || location.pathname == '/sale-test'){
        return(<Styled.Logo href="/"><Styled.LogoAIIllustration />Go to homepage </Styled.Logo>)
    }else{
      return(<Styled.Logo href="/"><LogoIllustration />Go to homepage </Styled.Logo>)
    }

  }

  const noFloatingHeader = () => {
    return ((location.pathname == '/lifetime/' || location.pathname == '/lifetime') 
            || (location.pathname == '/deal/' || location.pathname == '/deal') 
            || (location.pathname == '/save/' || location.pathname == '/save') 
            || (location.pathname == '/offer/' || location.pathname == '/offer') 
            || (location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal') 
            || (location.pathname == '/promo/' || location.pathname == '/promo')
            || (location.pathname == '/sale/' || location.pathname == '/sale') 
            || (location.pathname == '/yt-deal/' || location.pathname == '/yt-deal') 
            || (location.pathname == '/save-test/' || location.pathname == '/save-test')
            || (location.pathname == '/reviews/' || location.pathname == '/reviews') 
            || (location.pathname == '/reviews-test/' || location.pathname == '/reviews-test') 
            )
  }

  const coloredHeader = () => {
    // if(location.pathname == '/reviews/' || location.pathname == '/reviews') 
    //   return true
    return false;
    // return ((location.pathname == '/lifetime/' || location.pathname == '/lifetime') 
    //         || (location.pathname == '/deal/' || location.pathname == '/deal') 
    //         || (location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal') 
    //         || (location.pathname == '/save/' || location.pathname == '/save') 
    //         || (location.pathname == '/promo/' || location.pathname == '/promo') )
            
    //return (location.pathname == '/save/' || location.pathname == '/save') 
  }

  const transparentHeader = () => {
    return ((location.pathname == '/lifetime/' || location.pathname == '/lifetime') 
          || (location.pathname == '/deal/' || location.pathname == '/deal') 
          || (location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal') 
          || (location.pathname == '/save/' || location.pathname == '/save') 
          || (location.pathname == '/offer/' || location.pathname == '/offer') 
          || (location.pathname == '/promo/' || location.pathname == '/promo')
          || (location.pathname == '/sale/' || location.pathname == '/sale') 
          || (location.pathname == '/yt-deal/' || location.pathname == '/yt-deal') 
          || (location.pathname == '/sale-test/' || location.pathname == '/sale-test') 
          || (location.pathname == '/save-test/' || location.pathname == '/save-test')
          || (location.pathname == '/sale-min/' || location.pathname == '/sale-min'))  
          || (location.pathname == '/' || location.pathname == '')
    //return (location.pathname == '/sale/' || location.pathname == '/sale') 
  }

  const showDoodle = () => {
    return false;
    //return (!location.pathname.includes('sale') && !location.pathname.includes('save-test'))    
  }

 
  return (
    <Styled.Wrapper isSticky={(noFloatingHeader()) ? false : y > 0} isColored={ (coloredHeader()) ? true : false } isTransparent={(transparentHeader()) ? true : false}  removeMB={(location.pathname == '/sale-test/' || location.pathname == '/sale-test') ? true : false}>
      <Styled.Container>
        {links()}
        <Nav />
      </Styled.Container>
      {(showDoodle()) ? <Styled.Doodle /> : ''}
    </Styled.Wrapper>
  );
};

